import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------
Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 252.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,252.000000) scale(0.100000,-0.100000)">



<path d="M1390 1660 l-34 -5 26 -17 27 -18 -2 -146 c-2 -142 -1 -147 21 -161
30 -20 28 -34 -9 -69 -17 -16 -40 -42 -50 -59 l-19 -30 0 73 0 72 -29 0 c-38
0 -87 41 -95 80 -9 47 17 98 58 115 59 25 66 16 66 -91 0 -84 9 -117 23 -81 9
23 -1 159 -12 170 -6 6 -11 46 -11 89 l0 78 -62 0 c-70 0 -123 -20 -177 -66
-50 -42 -71 -95 -71 -185 0 -156 85 -244 255 -264 l50 -6 93 86 c50 48 92 90
92 94 0 5 -23 6 -50 3 l-51 -5 1 172 c1 95 0 174 -2 175 -1 1 -19 -1 -38 -4z
m-86 -36 c10 -4 16 -18 16 -40 0 -30 -5 -36 -43 -54 -114 -54 -114 -201 -2
-251 41 -18 45 -23 45 -54 0 -43 -20 -50 -84 -31 -113 34 -160 98 -161 217 0
67 3 81 29 117 48 70 146 117 200 96z"/>
<path d="M1650 1590 l0 -77 41 -9 c113 -24 111 -182 -3 -200 l-38 -7 0 -80 0
-80 64 6 c36 3 80 12 98 20 50 21 109 76 129 122 25 55 25 175 0 230 -37 82
-127 137 -243 148 l-48 5 0 -78z m126 21 c52 -14 88 -42 121 -93 45 -73 35
-206 -20 -267 -31 -33 -115 -71 -159 -71 -37 0 -38 1 -38 39 0 36 3 41 44 61
66 31 95 86 82 153 -8 41 -57 93 -97 102 -26 6 -29 11 -29 45 0 21 6 41 13 43
13 5 16 5 83 -12z"/>
<path d="M1545 1564 l-90 -84 58 0 58 0 -2 -167 -1 -168 36 -3 c38 -3 49 12
17 23 -28 9 -31 25 -28 172 2 124 1 133 -21 155 -12 14 -22 27 -22 30 0 3 14
21 31 39 36 39 63 78 58 83 -2 2 -44 -34 -94 -80z"/>
<path d="M1629 1488 c-4 -50 -5 -146 -2 -160 2 -10 9 -18 14 -18 5 0 9 43 9
95 0 57 -4 95 -10 95 -5 0 -10 -6 -11 -12z"/>
<path d="M824 976 c-5 -14 -23 -99 -24 -108 0 -13 28 -9 34 5 5 10 7 10 12 0
3 -7 12 -13 20 -13 12 0 14 9 9 48 -4 26 -9 54 -11 63 -5 18 -34 21 -40 5z"/>
<path d="M992 978 c-18 -18 -15 -46 8 -60 21 -13 27 -38 10 -38 -5 0 -10 5
-10 10 0 6 -4 10 -10 10 -16 0 -11 -37 6 -44 21 -8 54 11 54 31 0 9 -12 30
-27 45 -25 26 -21 42 5 16 16 -16 29 -1 16 20 -14 22 -36 26 -52 10z"/>
<path d="M1067 983 c-14 -13 -7 -101 9 -117 32 -33 51 -13 55 58 3 41 0 61 -8
63 -10 3 -13 -11 -13 -51 0 -31 -4 -56 -10 -56 -5 0 -10 25 -10 55 0 51 -7 65
-23 48z"/>
<path d="M1413 983 c-20 -8 -15 -44 9 -70 12 -13 18 -26 13 -30 -4 -5 -10 -2
-12 5 -7 19 -23 14 -23 -7 0 -24 25 -34 51 -21 26 15 24 39 -8 72 -15 15 -21
28 -15 28 7 0 12 -4 12 -10 0 -5 7 -10 15 -10 18 0 20 21 3 38 -13 13 -24 14
-45 5z"/>
<path d="M1480 931 c0 -49 4 -62 19 -71 38 -20 51 -3 51 66 0 36 -4 64 -10 64
-5 0 -10 -25 -10 -55 0 -70 -18 -72 -22 -3 -4 77 -28 76 -28 -1z"/>
<path d="M1702 978 c-18 -18 -15 -94 4 -112 19 -20 29 -20 48 0 20 20 21 44 1
44 -8 0 -15 -7 -15 -15 0 -8 -4 -15 -10 -15 -5 0 -10 18 -10 40 0 22 5 40 10
40 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 18 0 18 5 5 31 -12 21 -40 25 -58 7z"/>
<path d="M1792 978 c-7 -7 -12 -33 -12 -60 0 -50 21 -75 54 -62 23 9 23 119 0
128 -22 8 -28 8 -42 -6z m38 -58 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40 0
22 5 40 10 40 6 0 10 -18 10 -40z"/>
<path d="M1948 983 c-10 -3 -18 -17 -21 -36 l-3 -32 -8 33 c-6 22 -14 32 -27
32 -16 0 -19 -8 -19 -60 0 -42 4 -60 13 -60 8 0 11 15 10 48 -2 35 -1 42 6 27
5 -11 10 -32 10 -47 1 -44 18 -32 29 20 l10 47 1 -47 c2 -69 19 -59 23 13 3
65 2 68 -24 62z"/>
<path d="M2030 925 c0 -52 3 -65 15 -65 13 0 15 8 10 38 -5 36 4 26 19 -20 3
-10 12 -18 21 -18 12 0 15 13 15 66 0 48 -3 65 -12 61 -7 -2 -13 -20 -13 -39
0 -44 -10 -40 -19 7 -4 21 -13 35 -21 35 -12 0 -15 -15 -15 -65z"/>
<path d="M2141 981 c-15 -10 -17 -109 -3 -122 4 -4 19 -5 35 -2 23 5 27 10 27
39 0 27 -4 34 -20 34 -17 0 -19 -5 -14 -25 4 -15 2 -25 -5 -25 -6 0 -11 17
-11 40 0 22 5 40 10 40 6 0 10 -4 10 -10 0 -5 7 -10 15 -10 18 0 20 21 3 38
-14 14 -28 15 -47 3z"/>
<path d="M890 920 l0 -60 34 0 c38 0 46 17 38 83 -4 35 -6 37 -38 37 l-34 0 0
-60z m50 0 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10
-18 10 -40z"/>
<path d="M1150 920 c0 -47 3 -60 15 -60 9 0 15 9 15 25 0 18 5 25 20 25 16 0
20 7 20 35 0 33 -2 35 -35 35 l-35 0 0 -60z"/>
<path d="M1238 920 l-3 -60 33 0 c17 0 32 5 32 10 0 6 -9 10 -20 10 -11 0 -20
7 -20 15 0 8 9 15 20 15 11 0 20 5 20 10 0 6 -9 10 -20 10 -11 0 -20 7 -20 15
0 8 9 15 20 15 11 0 20 5 20 10 0 6 -13 10 -29 10 -29 0 -29 -1 -33 -60z"/>
<path d="M1310 920 c0 -47 3 -60 15 -60 9 0 15 9 15 25 0 14 5 25 10 25 6 0
10 -11 10 -25 0 -16 6 -25 16 -25 8 0 12 5 9 11 -4 5 -7 32 -7 60 l1 49 -35 0
-34 0 0 -60z"/>
<path d="M1570 920 l0 -61 33 3 c32 3 32 3 32 58 0 55 0 55 -32 58 l-33 3 0
-61z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
